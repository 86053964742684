




























import {
PropType, computed, defineComponent,
onMounted, onUnmounted,
ref, toRefs,
} from '@nuxtjs/composition-api';
import HorizontalCard from '~/components/JetUi/organisms/Product/HorizontalCard.vue';
import SfProductCard from '~/components/JetUi/organisms/Product/SfProductCard.vue';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { useImage } from '~/composables';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { ProductStockStatus } from '~/modules/GraphQL/types';
import CategoryProductPrice from '~/modules/catalog/category/components/views/CategoryProductPrice.vue';
import type { Product } from '~/modules/catalog/product/types';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import { useProductsWithCommonProductCardProps } from './useProductsWithCommonCardProps';

export default defineComponent({
  components: {
    CategoryProductPrice,
    SfProductCard,
    SkeletonLoader,
    HorizontalCard,
  },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    showSkeleton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click:wishlist', 'click:add-to-cart'],
  setup(props) {
    const qty = ref(1);
    const { addItemToCart, isInCart } = useAddToCart();
    const { isInWishlist, addOrRemoveItem } = useWishlist();

    const { imageSizes: { productCard: imageSize } } = useImage();
    const { products } = toRefs(props);
    const { productsWithCommonProductCardProps } = useProductsWithCommonProductCardProps(products);
    const isClient = process.client;
    const windowWidth = ref(isClient ? window.innerWidth : 1200);
    const gridContainer = ref(null);
    const loadingProducts = ref(0);

    const mappedProducts = computed(() => productsWithCommonProductCardProps.value.map((product) => ({
      ...product,
      isInWishlist: isInWishlist({ product }),
    })));

    const calculateItemsPerRow = () => {
      if (gridContainer.value) {
        const containerWidth = gridContainer.value.clientWidth;
        const itemWidth = 250 + 16;
        const productsLength = products.value.length;
        const perView = Math.floor(containerWidth / itemWidth);
        loadingProducts.value = perView + perView - (productsLength % perView);
      }
    };
    const addItemToWishlist = async (product) => {
      await addOrRemoveItem({ product });
    };
    const handleQtyUpdate = (newQty: number) => {
      qty.value = newQty;
    };

    const isInStock = (product) => product.stock_status === ProductStockStatus.InStock;

    const handleResize = () => {
      calculateItemsPerRow();
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      if (isClient) {
        handleResize();
        window.addEventListener('resize', handleResize);
      }
    });

    onUnmounted(() => {
      if (isClient) {
        window.removeEventListener('resize', handleResize);
      }
    });

    return {
      isInWishlist,
      addItemToWishlist,
      addItemToCart,
      isInCart,
      imageSize,
      mappedProducts,
      handleQtyUpdate,
      qty,
      isInStock,
      windowWidth,
      gridContainer,
      loadingProducts,
    };
  },
});
