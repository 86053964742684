
















































import {
PropType, computed, defineComponent, ref, useContext,
} from '@nuxtjs/composition-api';
import { SfBadge, SfIcon } from '@storefront-ui/vue';
import { useImage } from '~/composables';
import { useUiNotification } from '~/composables/useUiNotification';
import { useAddToCart } from '~/helpers/cart/addToCart';
import type { ProductInterface } from '~/modules/GraphQL/types';
import { ProductStockStatus } from '~/modules/GraphQL/types';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import JetProductCardImage from '../../atoms/JetImage/JetProductCardImage.vue';
import JetUiButton from '../../atoms/JetUiButton/JetUiButton.vue';
import SfPrice from '../../molecules/Product/SfPrice.vue';

export default defineComponent({
  name: 'HorizontalCard',
  components: {
    SfPrice,
    JetUiButton,
    SfIcon,
    JetProductCardImage,
    SfBadge,
  },
  props: {
    product: {
      type: Object as PropType<ProductInterface>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { getMagentoImage } = useImage();
    const qty = ref(1);

    const productImage = computed(() => getMagentoImage(productGetters.getProductThumbnailImage(props.product)));
    const productPrice = productGetters.getPrice(props.product);
    const productName = computed(() => productGetters.getName(props.product));
    const productLink = computed(() => productGetters.getSlug(props.product));
    const { send: sendNotification } = useUiNotification();
    const { app } = useContext();
    const { addItemToCart } = useAddToCart();

    const addToCart = async () => {
      await addItemToCart({ product: props.product as any, quantity: qty.value });
      sendNotification({
        id: Symbol('Product added to cart'),
        message: app.i18n.t('Product added to cart') as string,
        persist: false,
        type: 'success',
        icon: 'check',
      });
    };

    const increaseQty = () => {
      qty.value++;
    };

    const decreaseQty = () => {
      if (qty.value > 1) {
        qty.value--;
      }
    };

    const validateQty = () => {
      if (!qty.value) return;

      if (qty.value < 1) {
        qty.value = 1;
      }
    };

    const isInStock = computed(() => props.product.stock_status === ProductStockStatus.InStock);

    return {
      productImage,
      productName,
      qty,
      productGetters,
      addToCart,
      increaseQty,
      decreaseQty,
      productLink,
      validateQty,
      productPrice,
      isInStock,
    };
  },
});
